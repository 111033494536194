import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {Typography, Button, IconButton, TextField} from "@mui/material";
import SearchBar from "../Searchbar";
import AddIcon from "@mui/icons-material/Add";
import {DataGrid} from "@mui/x-data-grid";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import blanktable from "../../assets/blanktable.png";
import "../../App.css";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import {EditOutlined, UploadFileOutlined} from "@mui/icons-material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import PropTypes from "prop-types";

const TextFieldDatePicker = (params) => {
  return (
    <TextField
      {...params}
      name={params.name}
      variant="outlined"
    />
  );
}

const DataTable = (
  {
    title,
    data,
    columns,
    handleChangeSearch,
    handleChangeMonthFilter,
    handleChangeYearFilter,
    placeSearch,
    searchTitle,
    onAdd,
    onEdit,
    onUpload,
    onDetail,
    onDelete,
    onFilter,
    totalData,
    onEmployee,
    loading = false,
    dashboard,
    filter,
  }) => {
  const [pagination, setPagination] = useState({page: 0, pageSize: 10});
  const [sorting, setSorting] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);

  /**
   * return fungsi model dari pagination.
   *
   * @param {model} object Page & page size saat ini.
   */
  const changePagination = (model) => {
    setPagination({...model});
  };

  /**
   * return fungsi model dari sorting.
   *
   * @param {model} object field & sort size saat ini dalam bentuk array.
   */
  const changeSort = (model) => {
    if (model.length > 0) {
      setSorting([{...model}]);
    } else {
      setSorting([
        {
          field: "",
          sort: "",
        },
      ]);
    }
  };

  const handleBuildList = (filter) => {
    onFilter(filter);
  };

  useEffect(() => {
    const filter = {
      sorting: sorting.length > 0 ? {...sorting[0]} : {field: "", sort: ""},
      ...pagination,
    };
    handleBuildList(filter);
  }, [sorting, pagination]);

  useEffect(() => {
    setPagination({...pagination, page: 0});
  }, [filter.search]);

  useEffect(() => {
    const temp = [...columns];
    if (onEdit || onDelete || onDetail)
      temp.push({
        field: "actions",
        headerName: "Action",
        width: 200,
        sortable: false,
        renderCell: (data) => {
          return (
            <div>
              {onEdit ? (
                <IconButton onClick={() => onEdit(data.id)}>
                  <EditOutlined/>
                </IconButton>
              ) : (
                <IconButton onClick={() => onDetail(data.id)}>
                  <PreviewIcon/>
                </IconButton>
              )}
              {onDelete && (
                <IconButton onClick={() => onDelete(data.id)}>
                  <DeleteIcon/>
                </IconButton>
              )}
            </div>
          );
        },
      });
    setDataColumns(temp);
  }, [columns]);

  return (
    <Grid container rowSpacing={3}>
      {!dashboard && (
        <>
          <Grid item xs={12} pb={2}>
            <Grid container className="containerHeader">
              <Grid item>
                <div className="dividerHeader"/>
              </Grid>
              <Grid item xs={11} sm={6}>
                <Typography variant="headerCardMenu">{`Master ${title}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            justifyContent="space-between"
            container
            xs={12}
            paddingTop={3}
          >
            {title !== "Holiday" ? (
              <Grid
                item
                xs={12}
                sm={4}
                alignSelf="center"
                sx={{textAlign: {xs: "start", sm: "end"}}}
              >
                <SearchBar
                  placeholder={placeSearch}
                  label={searchTitle}
                  onChange={handleChangeSearch}
                />
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                item
                xs={6}
                alignItems="center"
                justifyContent="flex-start"
                spacing={2}
              >
                <Grid item xs={12} sm={8} md={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["month"]}
                      openTo="month"
                      name="month"
                      label="Select Month"
                      inputFormat="MM"
                      onChange={handleChangeMonthFilter}
                      slots={{
                        textField: TextFieldDatePicker,
                      }}
                      slotProps={{
                        actionBar: {
                          actions: ["clear"],
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={8} md={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year"]}
                      openTo="year"
                      name="year"
                      label="Select Year"
                      inputFormat="YYYY"
                      defaultValue={dayjs(new Date().getFullYear().toString())}
                      onChange={handleChangeYearFilter}
                      slots={{
                        textField: TextFieldDatePicker,
                      }}
                      slotProps={{
                        actionBar: {
                          actions: ["clear"],
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            )}

            {onEmployee && (
              <Grid
                item
                xs={12}
                sm={4}
                mt={1}
                alignSelf="center"
                sx={{textAlign: {xs: "start", sm: "end"}}}
              >
                <Button
                  variant="contained"
                  className="button-text"
                  onClick={() => onEmployee()}
                  startIcon={<SyncOutlinedIcon/>}
                >
                  Synchronise
                </Button>
              </Grid>
            )}

            {onUpload && (
              <Grid
                container
                direction="row"
                item
                xs={12}
                sm={6}
                mt={1}
                gap={1}
                alignItems="center"
                sx={{justifyContent: {xs: "start", sm: "end"}}}
              >
                {onAdd && (
                  <Button
                    variant="contained"
                    onClick={() => onAdd()}
                    startIcon={<AddIcon/>}
                  >
                    {title}
                  </Button>
                )}
                <Button
                  variant="outlined"
                  onClick={() => onUpload()}
                  startIcon={<UploadFileOutlined/>}
                  sx={{paddingY: 1}}
                >
                  Upload {title}
                </Button>
              </Grid>
            )}

            {!onEmployee && !onUpload && (
              <Grid
                item
                xs={12}
                sm={4}
                mt={1}
                alignSelf="center"
                sx={{textAlign: {xs: "start", sm: "end"}}}
              >
                {onAdd && (
                  <Button
                    variant="contained"
                    onClick={() => onAdd()}
                    startIcon={<AddIcon/>}
                  >
                    {title}
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </>
      )}
      {data.length > 0 ? (
        <Grid item xs={12}>
          <DataGrid
            rows={data}
            columns={dataColumns}
            disableRowSelectionOnClick
            pageSizeOptions={[10, 25, 50, 100]}
            paginationMode="server"
            paginationModel={{...pagination}}
            onPaginationModelChange={(model) => changePagination(model)}
            onSortModelChange={(model) => changeSort(model)}
            disableColumnFilter
            loading={loading}
            disableColumnMenu
            rowCount={totalData}
            getRowId={(row) => row.id}
            sortingMode="server"
          />
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          minHeight="600px"
          alignContent="center"
          alignItems="center"
          display="flex"
          justifyContent="center"
          textAlign="center"
        >
          <Grid item xs={12} pb={3.75}>
            <img
              src={blanktable}
              alt="blank-table"
              style={{maxWidth: "100%", height: "auto"}}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="noDataTable">
              Sorry, the data you are looking for could not be found.
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

DataTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  columns: PropTypes.arrayOf(PropTypes.object),
  handleChangeSearch: PropTypes.func,
  handleChangeMonthFilter: PropTypes.func,
  handleChangeYearFilter: PropTypes.func,
  placeSearch: PropTypes.string,
  searchTitle: PropTypes.string,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onUpload: PropTypes.func,
  onDetail: PropTypes.func,
  onDelete: PropTypes.func,
  onFilter: PropTypes.func,
  totalData: PropTypes.number,
  onEmployee: PropTypes.func,
  loading: PropTypes.bool,
  dashboard: PropTypes.bool,
  filter: PropTypes.object
}

export default DataTable;
