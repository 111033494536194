import {InputAdornment, TextField} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import React from "react";
import PropTypes from "prop-types";

const alphanumericRegex = /^[a-zA-Z0-9]*$/;
const SearchBar = ({placeholder, onChange, label}) => {
  const handleInputChange = (event) => {
    const {value} = event.target;
    event.target.value = value.replace(/[^a-zA-Z0-9]/g, '');
    onChange(event)
  };
  return (
    <TextField
      placeholder={placeholder}
      className="customSearchBar"
      label={label}
      fullWidth
      onChange={handleInputChange}
      type="search"
      reg
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlinedIcon/>
          </InputAdornment>
        ),
      }}
    />
  );
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string
}

export default SearchBar;
