import React from "react";
import {Chip} from "@mui/material";
import PropTypes from "prop-types";

export const ChipComponent = (
  {
    label,
    color = "success",
    variant = "filled",
    sx,
  }) => {
  return <Chip label={label} variant={variant} color={color} sx={[sx]}/>;
};

ChipComponent.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  variant: PropTypes.string,
  sx: PropTypes.object
}